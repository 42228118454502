/* Register.css */
.register-container {
  margin-top: 50px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.register-container h2 {
  color: var(--blue-dark);
}

.register-container form {
  display: flex;
  flex-direction: column;
}

.register-container input {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
