.home-container {
    background-color: #ffffff;
  width: 100%;
  margin: 0;
}

/* Section 1: Welcome */
.home1-container {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  /* Center content horizontally */
  padding: 40px;
  height: 100vh;
}

.home1-container h1 {
  color:white;
  font-size: 75px;
  margin-top: 70px;

}

.home1-container {
  background-image: url('/public/residential.jpg');
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents tiling */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  height: 100vh;
  color: white; /* Ensures text is readable on the background */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Adds shadow to text for better visibility */
}

.home2-container p {
  font-size: 25px;
  max-width: 800px;
}

/* Section 2: Features */
.home3-container {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  /* Center content horizontally */
  padding: 40px;
  height: 100vh;
}

.home3-container h2 {
  color: black;
  font-size: 70px;
  margin-top: 70px;

}

.home3-content p {
  max-width: 900px;
  margin-top: 20px;
  font-size: 25px;
  color: black;
}

/* Section 3: Contact Us */
.home4-container {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  /* Center content horizontally */
  padding: 40px;
  height: 100vh;
}

.home4-container h2 {
  color: black;
  font-size: 70px;
  margin-top: 70px;
}

.home4-content p {
  max-width: 900px;
  margin-top: 20px;
  font-size: 25px;
}
.cards {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next row */
  gap: 20px;
  margin-top: 20px;
  justify-content: space-between; /* Space cards evenly */
}

.card {
  flex: 1 1 calc(50% - 20px); /* Each card takes 50% width minus gap */
  max-width: calc(50% - 20px); /* Prevent cards from exceeding half of the row */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.card h3 {
  margin-top: 0;
  color: #333;
}

.card p {
  color: #666;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}