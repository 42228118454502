/* RightSideFormLayout.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
}

.slide-in-right {
  animation: slide-in-right 0.5s forwards;
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.form-card {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 65%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 10px;
  left: 90%;
  margin: 0.75rem;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  float: right;
  z-index: 1;
}
