.page-not-found {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 70%;
  margin: 20% auto;

  p {
    line-height: 24px;
  }

  .back-to-home-link {
    border: 1px solid var(--blue-dark);
    border-radius: 15px;
    align-self: flex-start;
    padding: 4px 8px;
  }
}

.page-not-found .heading {
  font-size: 32px;
  color: var(--blue-dark);
}